<template>
  <div class="item-box">
    <span class="item-title">{{title}}</span>
    <span class="item-look"></span>
    <!-- <span class="item-look">查看</span> -->
  </div>
</template>

<script>
export default {
  name: "x-item-nav",
  data () {
    return {};
  },
  props: {
    title: {
      type: String,
      default: null
    }
  },
  created () { },
  methods: {}
};
</script>

<style scoped>
.item-box {
  position: relative;
  display: flex;
  padding: 0.2rem 0;
  justify-content: space-between;
  align-items: center;
  line-height: 1;
  margin: auto 0.15rem;
  border-bottom: solid 0.01rem #f6f8fb;
}
.item-box::before {
  position: absolute;
  right: 0.02rem;
  content: '';
  width: 0.07rem;
  height: 0.07rem;
  transform: rotate(45deg);
  border-top: solid 0.01rem #99a0aa;
  border-right: solid 0.01rem #99a0aa;
}
.item-title {
  font-size: 0.16rem;
  color: #30353d;
}
.item-look {
  margin-right: 0.2rem;
  font-size: 0.14rem;
  color: #d0d3d8;
}
</style>
