<template>
  <div class="app-box">
    <x-item-nav v-if="isDisPlay[0]" title="器械销售数据" @click.native="jumpPage(1);"></x-item-nav>
    <x-item-nav v-if="isDisPlay[1]" title="药品终端流向" @click.native="jumpPage(2);"></x-item-nav>
    <x-item-nav v-if="isDisPlay[2]" title="器械终端流向" @click.native="jumpPage(3);"></x-item-nav>
    <template v-if="!token.isMarketUser">
      <x-item-nav title="绩效信息" @click.native="jumpPage(4);"></x-item-nav>
      <x-item-nav title="拜访情况统计" @click.native="jumpPage(5);"></x-item-nav>
      <x-item-nav title="活跃度统计" @click.native="jumpPage(6);"></x-item-nav>
    </template>
  </div>
</template>

<script>
import itemNav from "@/components/itemNav";

export default {
  data () {
    return {
      token: JSON.parse(sessionStorage.getItem("token")),
      isDisPlay: [false, false, false]
    };
  },
  created () {
    document.title = "报表中心";
    this.setIsDisPlay();
  },
  methods: {
    setIsDisPlay () {
      if (this.token.isMarketUser) {
        this.token.marketAuthList.map(item => {
          switch (item) {
            case 1:
              this.isDisPlay[0] = true;
              break;
            case 2:
              this.isDisPlay[1] = true;
              break;
            case 3:
              this.isDisPlay[2] = true;
              break;
            default:
              break;
          }
        });
      } else {
        this.isDisPlay = [true, true, true];
      }
    },
    jumpPage (index) {
      let url = "";
      switch (index) {
        case 1:
          url = "/reportCenter/apparatus";
          break;
        case 2:
          url = "/reportCenter/drugs";
          break;
        case 3:
          url = "/reportCenter/apparatusFlow";
          break;
        case 4:
          url = "/reportCenter/achievements";
          break;
        case 5:
          url = "/reportCenter/visit";
          break;
        case 6:
          url = "/reportCenter/active";
          break;
        default:
          break;
      }
      this.$router.push({
        path: url
      });
    }
  },
  watch: {
    $route (to, from) {
      if (to.meta.index <= from.meta.index) {
        document.title = "报表中心";
      }
    }
  },
  components: {
    [itemNav.name]: itemNav
  }
};
</script>

<style scoped>
</style>
